/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --customizable-options-additional-information-color: #2c2c2c;
    --customizable-options-required-text-color: #dc6d6d;
}

.ProductBundleItem {
    &-Wrapper {
        margin-block-start: 26px;

        @include mobile {
            margin-block-end: 15px;
        }

        .Field {
            margin-block-start: 16px;

            &_type_select {
                margin-block-start: 0;
            }

            &_type_select + .Field-ErrorMessages {
                position: absolute;
                inset-inline-start: 0;
                inset-block-end: 10px;
                transform: translateY(100%);
            }

            &-Wrapper {
                &_type {
                    &_select, &_checkbox, &_radio {
                        flex: 1 1;
                    }
                }
            }
        }
    }

    &-Select.Field_hasError {
        margin-block-end: 20px;
    }

    &-PriceLabel {
        white-space: nowrap;
    }

    &-Radio, &-Checkbox {
        &_customQuantity {
            display: flex;

            .Field {
                margin-block-start: 0;

                &_type {
                    &_radio, &_checkbox {
                        flex: 1 1;
                        padding-block-start: 8px;
                    }
                }
            }

            [type="number"] {
                & ~ button {
                    width: 36px;
                    height: 36px;
                }
            }

            & + & {
                margin-block-start: 10px;
            }
        }
    }

    &-DropdownWrapper {
        display: flex;
        align-items: flex-start;

        &_customQuantity {
            .Field {
                &_type {
                    &_number {
                        margin-inline-start: 16px;
                        margin-block-start: 0;
                    }
                }
            }

            [type="number"] {
                & ~ button {
                    width: 44px;
                    height: 48px;
                }
            }
        }
    }

    &-Qty.Field {
        margin-block: 0;
        margin-inline-start: 20px;

        .ProductBundleItem-Qty {
            opacity: 1;
        }
    }

    &-Select {
        flex-grow: 1;
        margin: 0;
    }

    &-Content {
        margin-block-start: 17px;

        .Field {
            margin-block-start: 12px;
        }
    }

    &-Required {
        color: var(--customizable-options-required-text-color);
        margin-block-start: 6px;

        @include mobile {
            margin-block-start: 7px;
        }
    }

    &-Information {
        color: var(--customizable-options-additional-information-color);
        font-size: 12px;
        margin-block-start: 6px;

        @include mobile {
            margin-block-start: 7px;
        }
    }

    &-ExpandableContentContent {
        &_isContentExpanded {
            @include mobile {
                padding-block-start: 0;
                padding-block-end: 14px;
                padding-inline: 14px;
            }

            .Field {
                &:first-child {
                    margin-block-start: 0;
                }
            }
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            font-weight: normal;
        }

        .ExpandableContent-HeadingAdditional {
            font-weight: bold;
        }
    }

    &-Heading {
        text-transform: uppercase;
        letter-spacing: .3em;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
        margin-block-end: 12px;
    }

    &_isPlaceholder {
        animation: var(--placeholder-animation);
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        max-width: 270px;
    }
}
