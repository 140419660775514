/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.ProductLinks {
    width: 100%;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block-start: 80px;
        padding-block-end: 0px;
        display: flex;
        flex-direction: column;
        row-gap: 40px;

        @include mobile {
            padding-block-start: 40px;
            padding-block-end: 0px;
            padding-inline: 0;
            row-gap: 24px;
        }
    }

    &-List {
        display: grid;
        grid-gap: 12px;
        grid-template-columns: 1fr 1fr;

        @include mobile {
            grid-gap: 14px;
            display: flex;
            flex-wrap: nowrap;
        }

        @include desktop {
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 24px;
            justify-content: center;
        }

        @include narrow-desktop {
            grid-template-columns: repeat(4, 1fr);
        }

        @include tablet {
            grid-template-columns: repeat(3, 1fr);
        }

        &Wrapper {
            @include mobile {
                overflow-x: scroll;
            }
        }

        .ProductCard {
            @include mobile {
                flex: 0 0 40%;
            }
            &-AddToCartLink, .AddToCart {
                .CartIcon {
                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }

    &-Title {
        margin: 0;
        align-self: center;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        color: #71333F;
        @include mobile {
            font-size: 24px;
        }

    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }
}
