/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */
 /* stylelint-disable */ 

:root {
    --price-color: #{$black};
    --price-with-discount-color: #b10d0d;
    --price-discount-color: #0A0903;
}

.ProductPrice, .ProductPrice * {
    -ms-overflow-style: none;
    /* for Internet Explorer, Edge */
    scrollbar-width: none;
    /* for Firefox */
}
.ProductPrice::-webkit-scrollbar, .ProductPrice *::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}
.ProductPrice {
    display: flex;
    align-items: baseline;
    color: var(--price-color);
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
    line-height: 27px;
    margin-block-end: 0;
    margin-block-start: 0;
    vertical-align: middle;
    overflow: scroll;

    @include desktop {
        //min-height: 40px;
    }

    @include mobile {
        font-size: 18px;
    }

    &-Price {
        white-space: nowrap;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        text-decoration: none;
        @include mobile {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &-HighPrice {
        white-space: nowrap;
        margin-left: 16px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-decoration-line: line-through;
        color: #818284;
        @include mobile {
            margin-left: 8px;
            font-size: 18px;
            line-height: 22px;
        }
    }

    &-PriceValue {
        white-space: nowrap;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        @include mobile {
            font-size: 18px;
            line-height: 22px;
        }
    }

    &, &-BundleFrom, &-BundleTo {
        &_hasDiscount {
            .ProductPrice {
                &-PriceBadge {
                    margin-block-start: 5px;
                    margin-block-end: -5px;
                }
            }
        }
    }

    &-Placeholder {
        &::after {
            content: 'aaaaa';
        }
    }

    &-SubPrice {
        display: block;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin-block-start: 4px;

        @include desktop {
            line-height: 16px;
        }
    }

    &-PriceBadge {
        font-size: 14px;
        font-weight: 400;
        margin-inline-end: 4px;
        white-space: nowrap;

        @include desktop {
            line-height: 20px;
        }
    }

    &-DiscountPercentage {
        margin-left: 16px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-transform: uppercase;
        color: #1ABF66;
        white-space: nowrap;
        @include mobile {
            margin-left: 8px;
            font-size: 14px;
            line-height: 17px;
        }
    }
}
