/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ProductRecommendation {
    padding-top: 20px;
    margin-bottom: 80px;
    @include mobile {
        padding-top: 0px;
        margin-top: 90px;
    }
    &-ProductList {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        margin-top: 80px;
        @include mobile {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
            margin-top: 30px;
        }
    }
    &-Heading {
        margin-bottom: 6px;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        text-align: center;
        color: #71333F;
        @include mobile {
            margin-bottom: 0px;
            font-size: 18px;
            line-height: 24px;
            text-align: center;
        }
    }
}
