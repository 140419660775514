/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

// util class, is applied on <html> on fullscreen image
/* stylelint-disable */
:root {
    --carousel-scroll-item-height: 38px;
    --carousel-scroll-margin-top: 28px;
}

.overscrollPrevented {
    overscroll-behavior: contain;
}

.ProductGallery {
    display: flex;
    flex-direction: column;
    height: 500px;
    margin-inline: 45px;

    img {
        object-fit: contain;
    }

    * {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    *::-webkit-scrollbar {
        display: none;
    }

    @include mobile {
        margin-inline: -16px;

        .Slider-Arrow {
            &_isPrev {
                inset-inline-start: 10px;
            }

            &_isNext {
                inset-inline-end: 10px;
            }
        }
    }

    @include narrow-desktop {
        // min-width: 507px;
    }

    @include mobile {
        height: calc(100vh / 2);
        margin-block-end: 16px;
    }

    &-Additional {
        display: flex;
        overflow: scroll;
        flex: 0 0 auto;
        min-height: calc(var(--carousel-scroll-item-height) + var(--carousel-scroll-margin-top));

        @include mobile {
            // display: none;
            min-height: 25px;
        }

        &_isImageZoomPopupActive {
            position: absolute;
            inset-block-end: 20px;
            inset-inline-start: 20px;

            .CarouselScrollItem {
                filter: opacity(0.75);

                &_isActive {
                    border-width: 2px;
                    filter: opacity(1);
                }
            }
        }

        &_isWithEmptySwitcher {
            height: 66px;
        }
    }

    &-SliderWrapper {
        flex: 1;
        height: 100%;
    }

    &-Slider {
        height: 100%;
        opacity: var(--sliderOpacity);

        &_isImageZoomPopupActive {
            cursor: pointer;
        }

        &_isZoomInCursor {
            cursor: zoom-in;
        }
    }

    .react-transform-component,
    .react-transform-element,
    &-SliderImage {
        height: 100%;
        width: 100%;
    }
}

.ProductGallery{
    &-PopupWraper {
        position: fixed;
        z-index: 20;
        height: calc(100% - 48px - var(--header-total-height));
        width: 100%;
        left: 0;
        top: var(--header-total-height);
        background: white;
        .ProductGallery {
            height: 100% !important;
        }
    }

    &-IMGbutton {
        width: 100%;
        height: 100%;
    }

    &-CloseButton {
        position: absolute;
        inset-block-start: 16px;
        inset-inline-end: 8px;
        z-index: 1;
    }
}