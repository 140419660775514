/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.ProductCard {
    flex-direction: column;
    width: 100%;
    max-width: 312px;
    height: 438px;

    @include mobile {
        height: 294px;
        max-width: 224px;
        min-width: 189px;
    }

    &-label {
        position: absolute;
        inset-block-start: 4px;
        z-index: 1;
        letter-spacing: 1px;
        text-transform: uppercase;
        font-family: 'Roboto';
        font-style: italic;
        font-weight: 900;
        font-size: 10px;
        line-height: 12px;

        @include mobile {
            font-size: 10px;
            line-height: 12px;
        }

        &-Triangle {
            position: absolute;
            right: -1px;
            top: 0;
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 7px solid white;
            @include mobile {
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
            }
        }

        &-left {
            display: flex;
            background: #000000;
            opacity: 0.9;
            padding: 4px 12px 4px 8px;
            font-family: 'Montserrat';
            font-style: italic;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #ffffff;
            
            @include mobile {
                font-size: 10px;
                line-height: 12px;
            }
        }

        &-right {
            color: #27B15E;
            inset-inline-end: 10px;
            @include mobile {
                display: none;
            }
        }

        &-middle {
            display: none;
            @include mobile {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: absolute;
                z-index: 5;
                padding: 4px 10px;
                top: -9px;
                left: 26px;
                width: 91px;
                height: 20px;
                font-family: 'Montserrat';
                font-style: italic;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                background: #EED899;
                border-radius: 4px;
            }
        }
    }

    &-FigureReview {
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 0;
        height: 276px;

        @include mobile {
            height: 146px;
        }
    }

    &-Figure {
        width: 100%;
        height: 276px;

        img {
            position: relative;
            width: 100%;
            height: 100%;
            object-position: 50% 50%;
            object-fit: contain;
        }

        @include mobile {
            height: 146px;
        }
    }

    &-Picture {
        padding-bottom: 0%;
    }

    &-Content {
        padding-block: 8px 12px;
        padding-inline: 8px;
        text-align: left;

        p {
            margin: 0;
        }

        @include mobile {
            padding-block: 12px;
        }
    }

    &-Name {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.05em;
        color: #000000;
        height: 35px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;

        @include mobile {
            height: 35px;
            font-size: 13px;
            line-height: 18px;
        }
    }

    &-UseOf {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 17px;
        color: #464646;
        // height: 15px;
        overflow: hidden;
        margin-top: 5px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        height: 32px;
        @include mobile {
            height: 30px;
            overflow: hidden;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 15px;
            color: #464646;
            margin-top: 3px;
        }
    }

    &-ProductSize {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #8B8B8B;
        margin-block-start: 4px;
        height: 15px;
        overflow: hidden;
    }

    &-Rating {
        display: flex;
        align-items: center;
        margin-block-start: 10px;
        height: 22px;

        &-card {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 7px;
            padding: 4px;
            background-color: #F0F0F0;
            border-radius: 20%;
            width: 52px;
            height: 22px;

            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #000000;
            }

            svg {
                inset-inline-end: 0;
            }

            @include mobile {
                // margin-block-start: 8px;
            }
        }

        &-review {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #161616;
            align-self: center;
            padding-inline-start: 8px;
        }
    }

    .ProductPrice {
        margin-block-start: 8px;
        &-HighPrice {
            opacity: 1;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 19px;
            text-decoration-line: line-through;
            color: #8B8B8B;

            @include mobile {
                font-size: 12px;
                line-height: 15px;
                margin-left: 4px;
            }
        }

        &-PriceValue, &-Price {
            margin-top: 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #000000;

            @include mobile {
                font-size: 12px;
                line-height: 15px;
            }
        }

        &-DiscountPercentage {
            letter-spacing: 1px;
            text-transform: uppercase;
            font-family: 'Roboto';
            font-style: italic;
            font-weight: 900;
            color: #27B15E;
            font-size: 14px;
            line-height: 15px;
            padding: 0;
            @include mobile {
                font-size: 10px;
                line-height: 12px;
                margin-left: 4px;
            }
        }

        &-Discount {
            display: none;
        }
    }

    &-Footer {
        width: 100%;
        inset-block-end: 0;
        overflow: hidden;
        //position: absolute;
        z-index: 21;
        padding-top: 0;
    }

    &-Footer > * {
        margin: 0!important;
    }

    &-AddToCart {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #000000;

        @include desktop {
            padding: 8px 10px;
            gap: 8px;
        }
        @include mobile {
            height: 38px !important;
            min-width: auto;
            svg {
                display: none;
            }
        }

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 900;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;

            @include mobile {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.1em;
            }
        }
    }

    &-PreviewOptions {
        position: absolute;
        inset-block-start: 0;
        inset-inline-start: 0;
        background: #ffffff;
        width: 100%;
        height: 100%;
        max-height: 308px;
        padding: 8px;
        z-index: 21;
        display: flex;
        flex-direction: column;

        &Header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-block-end: 10px;
        }

        &Heading {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 600;
            margin-block-end: 0;
        }

        .ProductConfigurableAttributes-SwatchList {
            flex-direction: row;
        }

        .ProductActions-AttributesWrapper {
            flex: 1;

            .ProductCard-Attributes {
                height: 100%;

                .ProductConfigurableAttributes-Title {
                    display: block;
                    margin-block-start: 0;
                    margin-block-end: 10px;
                }

                .ProductAttributeValue-Color {
                    border-radius: 30px;
                    width: 30px;
                    height: 30px;
                    margin-inline-end: 10px;
                    margin-block-end: 0px;

                    &::before {
                        inset-inline-start: 0;
                        inset-block-start: 0;
                        width: 30px;
                        height: 30px;
                    }

                    &::after {
                        border-left: 2px solid var(--option-check-mark-background);
                        height: calc(30px / 5);
                        width: calc(30px / 1.9);
                        transform: rotate(-45deg);
                        border-bottom: 2px solid var(--option-check-mark-background);
                        inset-inline-start: calc(30px / 4);
                        inset-block-start: calc(30px / 4);
                    }
                }
            }
        }
    }

    &Video-Link_active {
        height: 70%;
        background: white;
        bottom: 0;
    }

    &Video {
        &-overlap {
            position: fixed;
            bottom: 0;
            left: 0;
            background: #000000c4;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: flex-end;
            flex-direction: column;
        }
        &-Link {
            padding: 10px;
            padding-bottom: 90px;
            display: flex;
            flex-direction: column;
            row-gap: 0;
            overflow: hidden;
            width: 100%;
            .ProductCard-Link {
                height: auto;
            }
            &_M {
                display: grid;
                grid-template-columns: calc(20% - 10px) 60% calc(20% - 10px);
                column-gap: 10px;
                background-color: #FFFFFF;
                padding: 10px;
                align-items: center;
            }
        }
        &-FigureReview {
            background: var(--product-card-background);
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding: 0;
            max-height: 236px;
            min-height: auto;

            &_M {
                padding: 0px;
                height: 100%;
                width: 100%;
            }
        }
        &-Figure {
            width: 100%;
            min-height: 0;
            max-height: 236px;
            aspect-ratio: 1;

            img {
                object-fit: contain !important;
            }

            &_M {
                height: 100%;
                width: 100%;
            }
        }
        &-Content {
            padding-block: 10px;
            .ProductCard-Rating {
                margin-top: 0;
            }
        }
        &-viewMore {
            background-color: #000000;
            font-size: 14px;
            font-weight: 600;
            color: #FFFFFF;
            height: fit-content;
            border-radius: 4px;
            padding: 10px 0px;
        }
        &-DescriptionTab {
            height: 100%;
            overflow: scroll;
        }
        &-Info {
            &-Heading {
                font-size: 18px;
                font-weight: 400;
            }

            &-Wrapper {
                height: 75px;
                overflow: hidden;
                background: white;
                padding: 0 10px;
                //padding: 15px 30px;

                h3 {
                    font-size: 21px;
                    border-bottom: 2px solid #dedede;
                    display: inline-block;
                    clear: inline-end;
                }
            }

            &-Wrapper.active {
                height: auto;
            }

            &-ReadMore {
                text-align: left;
                padding-top: 5px;
                font-weight: 500;
                border-top: none;
                color: #009f60;
                margin-top: 0px;
                text-transform: capitalize;
                font-size: 16px;
                padding-left: 10px;
                @include mobile {
                    text-align: left;
                    padding-top: 0px;
                    font-weight: 500;
                    color: #009f60;
                    margin-top: 0px;
                    //text-transform: capitalize;
                    font-size: 12px;
                }
            }
            
            &-ReadMore:hover {
                cursor: pointer;
            }
        }
    }
}
