/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
 /* stylelint-disable */
.ProductReviews {
    background-color: white;

    @include desktop {
        z-index: 1;
        padding: 16px;
    }

    &-Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;

        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            color: #71333F;            

            @include mobile {
                font-size: 18px;
                line-height: 24px;
            }
        }
        @include mobile {
            align-items: center;
            justify-content: center;
            width: 100%;
            max-width: 100%;
        }
    }

    &-Wrapper {
        display: flex;
        padding: 0;

        @include mobile {
            flex-direction: column;
            //row-gap: 24px;
            padding: 0;
        }
    }

    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 37px;
        width: 66%;
        min-width: 550px;

        @include mobile {
            row-gap: 24px;
            width: 100%;
            min-width: auto;
        }
    }

    &-Left {
        width: 49%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 0;
        height: 100%;

        @include desktop {
            justify-content: flex-start;
            column-gap: 26px;
            border-right: 1px solid black;
        }

        @include mobile {
            width: 100%;
            column-gap: 16px;
            padding: 16px;
            padding-bottom: 0;
            border-bottom: 1px solid #D9D9D9;
            align-items: center;
        }

        &-Row1 {
            display: flex;
            column-gap: 16px;
            align-items: center;
            span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 600;
                font-size: 40px;
                line-height: 49px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #000000;
            }

            @include mobile {
                column-gap: 8px;
                span {
                    font-size: 24px;
                    line-height: 27px;
                }
            }
        }
        &-Row2 {
            display: flex;
            p {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.05em;
                color: #000000;
                
            }

            @include mobile {
                row-gap: 4px;
                span {
                    font-size: 14px;
                    line-height: 16px;
                }
                p {
                    font-size: 12px;
                    line-height: 14px;
                }
            }
        }
    }

    &-Right {
        width: 51%;
        display: flex;
        flex-direction: column;
        padding-inline-start: 16px;
        row-gap: 16px;
        align-items: center;
        justify-content: center;
        span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #8B8B8B;
        }

        @include mobile {
            width:  100%;
            padding-inline-start: 0;
            row-gap: 16px;

            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-Summary {
        display: flex;
        flex-direction: column;
        &-Wrap {
            align-self: stretch;
            display: flex;
            flex-wrap: wrap;
            background-color: transparent;
            align-items: center;
            padding: 17px 24px;
    
            @include mobile {
                margin-inline: -16px;
                flex-direction: column;
                row-gap: 8px;
                padding-block-end: 0px;
                border-bottom: none;
            }
        }
    }

    &-SummaryRating {
        --star-size: 27px;

        .ProductReviewRating-Counter {
            display: none;
        }
    }

    &-ExpandableContentHeading,
    &-ExpandableContentButton {
        @include desktop {
            display: none;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }

        &_isContentExpanded {
            padding-block-start: 0;
        }

        .ProductReviewRating {
            align-items: center;
            margin-block-start: -5px;
        }
    }

    &-ReviewButton {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #8B8B8B;
        }

        @include mobile {
            flex-direction: column;
            row-gap: 12px;
            span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    & &-Button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #000000;
        border: 1px solid #000000;
        background-color: white;
        max-width: 256px;
        &:hover {
            height: 40px;
            background-color: #000000;
            color: white;

        }

        @include mobile {
            position: relative;
            width: 100%;
            max-width: 100%;
            //margin-block-start: 18px;
            line-height: 20px;
            margin-top: 24px;
            font-size: 11px;
            font-weight: 900;
        }
    }

    &-Container {
        display: flex;
        flex-direction: column;
        row-gap: 34px;
        &-Title{
            font-family: 'PP Pangram Sans';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 18px;
            color: #818284;
        }

        @include mobile {
            row-gap: 24px;
            > span {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    &-SummaryDetails {
        display: inline-block;
        margin-inline-start: 12px;
        margin-block: auto;
        font-size: 24px;
        font-weight: 700;
        line-height: 28px;
        font-style: normal;

        @include mobile {
            margin-inline-start: 14px;
            line-height: 24px;
            font-size: 18px;
        }

        span {
            &::before {
                content: ' / ';
            }
        }
    }

    &Bar {
        &-Wrap {
            display: flex;
            width: 100%;
            column-gap: 16px;
        }
        &-stars {
            width: 116px;
            display: flex;
            column-gap: 4px;
            svg {
                width: 20px;
                height: 20px;
            }
            @include mobile {
                column-gap: 2px;
                svg {
                    width: 16px;
                    height: 16px;
                }
            }
        }
        &-Bar {
            width: 100%;
            height: 16px;
            background-color: #F5F5F5;
        }
        &-Text {
            width: 80px;
            span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 15px;
                display: flex;
                align-items: center;
                text-transform: capitalize;
                color: #000000;
            }
        }
    }
}

#ProductReviews {
    top: -120px;

    @include mobile {
        top: -100px;
    }
}
