/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.VideoSliderProduct {
    &-Image {
        top: -35%;
        height: 45px;
        width: 45px;
        left: 50%;
        transform: translateX(-50%);
        box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        overflow: hidden;  
        position: absolute;  
    }
    &-Name {
        padding-top: 30px;
        margin-top: 5px;
        padding-inline: 8px;
        font-size: 14px;
        text-align: center;
        letter-spacing: -0.4px;
        font-weight: 700;
        color: var(--color-black);
        line-height: 1rem;
    }
    &-PriceWrapper {
        .ProductPrice {
            text-align: center;
            font-size: 14px;
            letter-spacing: -0.4px;
            font-weight: 700;
        }
    }
}
