/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';


/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductInformation {
    font-size: 14px;
    line-height: 20px;
    //max-width: 900px;
    margin-inline: auto;

    &-Wrapper {
        padding: 0;

        @include desktop {
            padding-block: 32px;
        }
    }

    &-ExpandableContentContent {
        line-height: 20px;
        text-align: justify;

        + div {
            margin-block-end: 12px;
        }

        &_isContentExpanded {
            margin-block-end: 16px;
        }
    }

    &-ExpandableContentHeading {
        @include desktop {
            display: none;
        }
    }

    &-Placeholder {
        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }

        span {
            display: block;
            margin-block-end: 12px;

            @include mobile {
                margin-block-end: 14px;
            }
        }
    }

    &-Content {
        @include desktop {
            margin-block-end: 12px;
        }
    }

    &-Description {
        @include description-tags {
            font-size: 17px;
            line-height: 23px;
            
            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }

        li {
            &::before {
                position: relative;
                margin-inline-start: 10px;
                margin-inline-end: -25px;
                inset-block-start: -1px;
            }
        }

        @include default-list;

        @include mobile {
            line-height: 20px;
        }
    }

    @include desktop {
        min-height: 291px;
        width: 100%;
    }
    
    @include mobile {
        //min-height: 291px;
        width: 100%;
    }
    
    &Wrapper {
        height: 75px;
        overflow: hidden;
        background: white;
        padding: 0 10px;
        //padding: 15px 30px;

        h3 {
            font-size: 21px;
            border-bottom: 2px solid #dedede;
            display: inline-block;
            clear: inline-end;
        }
    }

    &Wrapper.active {
        height: auto;
    }
}
