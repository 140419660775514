/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.productVideos {
    &-Wrap {
        max-width: 100%;
        max-height: 100%;
        aspect-ratio: 1 / 1.72;
        overflow: hidden;
        border-radius: 10px;
        padding-inline: 4px;

        video {
            border-radius: 2px;
            object-fit: cover;
            object-position: center top;
            z-index: 1;
            border: 0px solid rgb(255, 255, 255);
            width: 100%;
            height: 100%;
        }
    }

    &-Content {
        position: absolute;
        z-index: 999;
        background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 63%, rgba(0, 0, 0, 0) 86%);
        inset-block-end: 0;
        color: #fff;
        width: 100%;

        img {
            width: 33px;
            height: 33px;
        }
        
        &-text {
            width: -webkit-fill-available;
            span {
                font-size: 12px;
                text-align: start;
                font-weight: 600;
                line-height: normal;
                letter-spacing: normal;
                opacity: 1;
                margin: 0px;
                padding: 0px;
                color: rgb(255, 255, 255);
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                display: -webkit-box;
                overflow: hidden;
            }

            del {
                font-size: 11px;
                color: #aaa;
            }

            .ProductPrice-DiscountPercentage {
                display: none;
            }
        }
    }

    &-Wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 10px;
        gap: 10px;
    }

    &-AddToCart {
        margin: 0 !important;
        background: none;
        border: 0;
        height: auto !important;
        position: absolute;
        right: 10px;
        bottom: 10px;
        width: auto !important;
        svg {
            height: 30px !important;
            width: 30px !important;
            margin-right: 0 !important;
        }
        &:disabled {
            opacity: .5 !important;
        }
        &:hover {
            height: auto !important;
            background: none !important;
        }
    }

    &-flex {
        display: flex;
        overflow: auto;

        .productVideos {
            &-Wrap {
                aspect-ratio: 1 / 1.72;
                width: 300px;

                &:first-of-type {
                    margin-inline-start: auto;
                }
    
                &:last-of-type {
                    margin-inline-end: auto;
                }
            }
        }
    }
}
