/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
.HomeProductsSlider {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }
    @include mobile {
        height: 446px;
        padding-block: 23px;
    }
    @include desktop {
        justify-content: space-between;
        height: auto;
        padding-block: 40px;
    }
    &-title {
        padding-block-end: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include mobile {
            padding-block-end: 24px;
        }
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 20px;
                line-height: 27px;
            }
        }
    }
    .slick {
        &-slide{
            width: 295px;
            @include mobile {
                width: 280px;
            }
            > div {
                //display: flex;
                //align-items: center;
            }
        }
        &-arrow {
            width: 64px;
            height: 64px;
            z-index: 4;
            @include mobile {
                width: 42px;
                height: 42px;
            }
        }
        &-prev {
            left: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
                transform: rotate(180deg);
            }
            &::before {
                display: none;
            }
        }
        &-next {
            right: 2%;
            opacity: 0.5;
            background: white;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            svg {
                height: auto;
            }
            &::before {
                display: none;
            }
        }
    }
    &-products {
        margin-inline: auto;
        //max-width: var(--content-wrapper-width);
        max-width: 95%;
        margin-inline: auto 0%;
        height: 470px;
        width: 100%;
        @include mobile {
            height: 342px;
        }
        .slick-slider, .slick-initialized {
            width: 100%;
            height: 440px;
            overflow: hidden;
        }
        .ProductCard {
            max-width: 285px;
            //margin: auto;
            @include mobile {
                max-width: 189px;
            }
        }
        ul {
            display: flex;
            flex-direction: row;
            overflow-x: scroll;
            column-gap: 8px;
            li {
                margin-left: 10px;
                flex: 0 0 302px;
                height: 422px;
            
                @include mobile {
                    margin-left: 0;
                    flex: 0 0 44%;
                    height: 283px;
                }
            }
        }
    }
}