/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */ 
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.HideShow-button {
    color: #6a4f00;
    font-weight: 700;
    font-size: 14px;
    position: absolute;
    bottom: 16px;
    right: 16px;
    cursor: pointer;

    &:hover {
        color: #d6ae37;
        font-weight: 900;
    }
}

//  About
@include desktop {
    .About {
        padding: 25px 16px;
        display: flex;
        background-color: #FFFFFF;
    }

    .About .Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;
    }

    .About .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .About .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 66%;
        min-width: 550px;
        row-gap: 0px;
    }

    .About span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #161616;
    }

    .About p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: justify;
        letter-spacing: 0.03em;
        color: #000000;
    }

    .About li,
    .About li::before {
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: left;
        letter-spacing: 0.03em;
        color: #000000;
    }
}
@include mobile {
    .About {
        flex-direction: column;
        row-gap: 16px;
        padding: 10px 0px;
        display: flex;
        background-color: #FFFFFF;
    }

    .About .Title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .About .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #71333F;
    }

    .About .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        row-gap: 0px;
    }

    .About span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        text-align: left;
        color: #161616;
    }

    .About p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: justify;
        color: #383838;
    }

    .About li,
    .About li::before {
        display: flex;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #383838;
    }
}
// HeroIngredients
@include desktop {
    .HeroIngredients {
        padding: 25px 16px;
        display: flex;
        background-color: #FFFFFF;
    }

    .HeroIngredients .Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;
    }

    .HeroIngredients .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .HeroIngredients .Row {
        display: flex;
        justify-content: space-around;
        width: 66%;
        min-width: 550px;
        row-gap: 40px;
        flex-wrap: wrap;
    }

    .HeroIngredients .Col {
        display: flex;
        flex-direction: column;
        width: (90% / 3);
    }

    .HeroIngredients .Image {
        padding-inline: 40px;
        padding-bottom: 40px;
    }

    .HeroIngredients .Content {
        display: flex;
        flex-direction: column;
        margin-inline: 10px;
        // row-gap: 16px;
        // margin-top: 40px;
    }

    .HeroIngredients .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #161616;
        padding-bottom: 12px;
    }

    .HeroIngredients .Content p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #000000;
    }
}
@include mobile {
    .HeroIngredients {
        flex-direction: column;
        row-gap: 16px;
        padding: 10px 0px;
        display: flex;
        background-color: #FFFFFF;
    }

    .HeroIngredients .Title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .HeroIngredients .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #71333F;
    }

    .HeroIngredients .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        row-gap: 24px;
    }

    .HeroIngredients .Col {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .HeroIngredients .Img {
        padding-inline-end: 16px;
        width: 40%;

    }

    .HeroIngredients .Content {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        max-width: 260px;
        align-items: center;
    }

    .HeroIngredients .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        text-align: left;
        color: #161616;
    }

    .HeroIngredients .Content p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: center;
        color: #000000;
    }
}
// Benefits
@include desktop {
    .Benefits {
        padding: 25px 16px;
        display: flex;
        background-color: #FFFFFF;
    }

    .Benefits .Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;
    }

    .Benefits .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .Benefits .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 66%;
        min-width: 550px;
    }

    .Benefits .Row hr {
        margin: 0;
    }

    .Benefits .Content {
        display: flex;
        flex-direction: column;
        // row-gap: 10px;
        margin-top: 16px;
    }

    .Benefits .Content:first-child {
        margin-top: 0px !important;
    }

    .Benefits .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
        color: #161616;
    }

    .Benefits .Content p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #000000;
        text-align: justify;
        // width: 82%;
        margin-bottom: 8px;
    }
}
@include mobile {
    .Benefits {
        flex-direction: column;
        row-gap: 16px;
        padding: 10px 0px;
        display: flex;
        background-color: #FFFFFF;
    }

    .Benefits .Title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .Benefits .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #71333F;
    }

    .Benefits .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        /* row-gap: 16px; */
    }

    .Benefits .Row hr {
        margin: 8px 0 0 0;
    }

    .Benefits .Content {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
        width: 100%;
        margin-top: 14px;
    }

    .Benefits .Content:first-child {
        margin-top: 0px !important;
    }

    .Benefits .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
    }

    .Benefits .Content p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: justify;
        color: #000000;
        margin-bottom: 2px;
    }
}
// HowToUse
@include desktop {
    .HowToUse {
        padding: 25px 16px;
        display: flex;
        background-color: #FFFFFF;
    }

    .HowToUse .Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;
    }

    .HowToUse .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .HowToUse .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 66%;
        min-width: 550px;
        row-gap: 40px;
    }

    .HowToUse .Left {
        width: 100%;
    }

    .HowToUse .Right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .HowToUse .Container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        row-gap: 10px;
        align-content: baseline;
    }

    .HowToUse .Img {
        min-width: 21%;
        max-width: 97px;
        height: 100%;
        max-height: 97px;
    }

    .HowToUse .Content {
        display: flex;
        // flex-direction: column;
        // column-gap: 16px;
        width: 100%;
        align-items: baseline;
    }

    .HowToUse .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #000000;
    }

    .HowToUse .Content p {
        margin-bottom: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #000000;
        text-align: justify;
    }
    .HowToUse li::before {
        left: 0 !important;
        position: relative !important;
        padding-right: 10px !important;
        font-weight: 600 !important;
        line-height: 18px !important;
        font-size: 12px !important;
    }
}
@include mobile {
    .HowToUse {
        flex-direction: column;
        row-gap: 16px;
        padding: 10px 0px;
        display: flex;
        background-color: #FFFFFF;
    }

    .HowToUse .Title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .HowToUse .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #71333F;
    }

    .HowToUse .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        row-gap: 40px;
    }

    .HowToUse .Left {
        width: 100%;
    }

    .HowToUse .Right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        // row-gap: 16px;
        width: 100%;
    }

    .HowToUse .Container {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        justify-content: space-between;
        width: 100%;
        row-gap: 10px;
    }

    .HowToUse .Img {
        width: 32%;
        height: auto;
        max-width: 120px;
        max-height: 120px;
        aspect-ratio: 1;
    }

    .HowToUse .Content {
        display: flex;
        /* flex-direction: column; */
        // column-gap: 16px;
        /* width: 80%; */
        align-items: baseline;
    }

    .HowToUse .Content span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        text-wrap: nowrap;
    }

    .HowToUse .Content p {
        margin-bottom: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.02em;
        text-align: justify;
        color: #000000;
    }
    .HowToUse li::before {
        left: 0 !important;
        position: relative !important;
        padding-right: 10px !important;
        font-weight: 600 !important;
        line-height: 14px !important;
        font-size: 12px !important;
    }
}
// DescriptionTab
@include desktop {
    .DescriptionTab {
        padding: 25px 16px;
        display: flex;
        background-color: #FFFFFF;
    }

    .DescriptionTab .Title {
        display: flex;
        align-items: flex-start;
        width: 36%;
        max-width: 430px;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .DescriptionTab .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 43px;
        color: #71333F;
    }

    .DescriptionTab .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 66%;
        min-width: 550px;
        row-gap: 24px;
        overflow: hidden;
    }

    .DescriptionTab .Left {
        width: 100%;
    }

    .DescriptionTab .Right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        width: 100%;
    }

    .DescriptionTab .Container {
        display: flex;
        justify-content: space-between;
        width: 33%;
        row-gap: 10px;
        align-content: baseline;
    }

    .DescriptionTab .Img {
        min-width: 21%;
        max-width: 97px;
        height: 100%;
        max-height: 97px;
    }

    .DescriptionTab .Content {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #000000;
        text-align: justify;
        margin-bottom: 10px;
    }
}
@include mobile {
    .DescriptionTab {
        flex-direction: column;
        row-gap: 16px;
        padding: 10px 0px;
        display: flex;
        background-color: #FFFFFF;
    }

    .DescriptionTab .Title {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

    .DescriptionTab .Title span {
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #71333F;
    }

    .DescriptionTab .Row {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        row-gap: 40px;
    }

    .DescriptionTab .Left {
        width: 100%;
    }

    .DescriptionTab .Right {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 16px;
        width: 100%;
    }

    .DescriptionTab .Container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        row-gap: 10px;
        align-content: baseline;
    }

    .DescriptionTab .Img {
        width: 64px;
        height: 64px;
    }

    .DescriptionTab .Content {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
        text-align: justify;
        margin-bottom: 0;
    }
}


.ProductPage {
    * {
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
    }
    *::-webkit-scrollbar {
        display: none;
        /* for Chrome, Safari, and Opera */
    }
    @include tablet {
        padding-block-start: 0;
        margin-block-start: 0;
    }
    @include mobile {
        //padding-block-end: 133px;
        margin-inline: 16px;
        padding-bottom: 0;
        margin-block-end: 0;
    }

    :root {
        --product-footer-margin-bottom: 74px;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 47% 53%;
        grid-column-gap: 24px;
        grid-template-rows: min-content min-content;
        padding-block-start: 24px;

        @include desktop {
            grid-column-gap: 48px;
            grid-template-areas: '. actions';
            padding-block-end: 72px;

            .ProductActions {
                grid-area: actions;
            }

            @include ultra-narrow-desktop {
                grid-column-gap: 0px;
                grid-template-columns: repeat(2, 50%);
            }
        }

        @include mobile {
            grid-template-columns: 100%;
            padding: 0;
        }

        .RelatedProducts {
            display: none;

            @include desktop {
                display: block;
            }
        }
    }

    &-Faqs {
        .ExpandableContent {
            @include mobile {
                border-block-start: none;
                margin-bottom: 10px;
            }
        }
        .ExpandableContent-Button{
            padding: 1em;
            background: #fbfbfb;
        }
        .ExpandableContent-Heading {
            font-weight: 700;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: .3em;
            font-size: 14px;
        }
        .ExpandableContent-Content {
            &_isContentExpanded {
                padding: 1em;
            }
        }
    }

    &-DescriptionTab {
        padding: 30px;
        @include mobile {
            padding: 0;
        }
        &Heading {
            color: var(--h2-color-mobile);
            font-size: var(--h2-font-size-mobile);
            font-style: var(--h2-font-style-mobile);
            font-weight: var(--h2-font-weight-mobile);
            line-height: var(--h2-line-height-mobile);
            margin-bottom: 21px;
            margin-top: 25px;
            text-transform: uppercase;
            text-align: center;
        }
    }

    &-HowToUseTab {
        padding: 30px;
        @include mobile {
            padding: 0;
        }
        &Heading {
            color: var(--h2-color-mobile);
            font-size: var(--h2-font-size-mobile);
            font-style: var(--h2-font-style-mobile);
            font-weight: var(--h2-font-weight-mobile);
            line-height: var(--h2-line-height-mobile);
            margin-bottom: 21px;
            margin-top: 25px;
            text-transform: uppercase;
            text-align: center;
        }
    }

    .ProductReviews {
        @include mobile {
            margin-block: 20px;
        }
    }
}

.slick {
    &-arrow {
        width: 64px;
        height: 64px;
        z-index: 4;
    }
    &-prev {
        left: 2%;
        opacity: 0.5;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: auto;
            transform: rotate(180deg);
        }
        &::before {
            display: none;
        }
    }
    &-next {
        right: 2%;
        opacity: 0.5;
        background: white;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            height: auto;
        }
        &::before {
            display: none;
        }
    }
}

.PDPLafzBlog {
    margin-inline: auto;
    max-width: var(--content-wrapper-width);
    display: flex;
    flex-direction: column;
    row-gap: 40px;
    padding-block-start: 40px;
    padding-block-end: 80px;

    @include mobile {
        row-gap: 24px;
        padding-block-start:2 0px;
        padding-block-end: 40px;
    }

    &-title {
        align-self: center;
        span {
            font-family: 'Playfair Display';
            font-style: normal;
            font-weight: 400;
            font-size: 32px;
            line-height: 43px;
            text-align: center;
            color: #71333F;
            @include mobile {
                font-size: 24px;
            }
        }

    }

    &-slides {
        // display: flex;
        // flex-direction: row;
        //overflow: scroll;
        .slick-slider, .slick-initialized {
            width: 100%;
        }
        
        .slick-track {
            display: flex;
            flex-direction: row;
            //overflow: scroll;
            column-gap: 24px;
            
            @include mobile {
                column-gap: 8px;
            }
        }
    }

    &-Container {
        display: flex!important;
        flex-direction: column;
        align-items: center;
        padding: 0px;
        row-gap: 24px;
        width: 100% !important;

        @include mobile {
            width: 100% !important;
            row-gap: 8px;
        }
    }
    &-Image {
        width: 100%;
        max-height: 230px;
        overflow: hidden;

        @include mobile {
            width: 100%;
            height: auto;
        }
    }
    &-Content {
        display: flex;
        flex-direction: column;
        row-gap: 16px;
        @include mobile {
            row-gap: 8px;
        }
    }
    &-Title {
        height: 56px;
        overflow: hidden;
        letter-spacing: 0.05em;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #000000;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 4.2rem;
        margin-top: 10px;
        @include mobile {
            height: 64px;
            font-size: 12px;
            line-height: 16px;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                max-height: 3.2rem;
        }
    }
    &-Description {
        height: 48px;
        overflow: hidden;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #464646;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 4.2rem;
        @include mobile {
            display: none;
            height: unset !important;
            font-size: 14px;
            line-height: 14px;
        }
    }
}
