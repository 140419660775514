/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

 /* stylelint-disable */
.ProductActions {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    &-Attributes {
        padding: 0;
    }
    // .Breadcrumbs {
    //     ul {
    //         justify-content: flex-start;
    //     }
    // }
    .Breadcrumbs {
    .ContentWrapper {
        padding-inline: 0;
    }
    .Breadcrumb-Link {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        display: flex;
    }
    .Breadcrumb-Link-Name {
        white-space: nowrap;
    }
    }

    &-Brand {
        font-weight: bold;
        opacity: .48;
        font-size: 12px;
        line-height: 16px;
    }

    &-Title {
        text-transform: none;
        font-family: 'Playfair Display';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 120%;
        letter-spacing: 0.05em;
        color: #000000;
        
        @include mobile {
            font-size: 18px;
            font-weight: 600;
        }
    }

    &-UseOf {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.05em;
        color: #000000;
        
        @include mobile {
            font-size: 10px;
            line-height: 20px;
        }
    }

    &-ShortDescription {
        @include description-tags {
            line-height: 20px;
        }
        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #000000;

            @include mobile {
                font-size: 12px;
                line-height: 20px;
            }
        }

        div[itemprop="description"] {
            word-break: break-word;
        
            height: 42px;
            overflow: hidden;
            
            @include mobile {
                height: 60px;
            }

            li {
                margin: 0;
                padding-left: 16px;
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                &::before {
                    content: '.';
                    left: 0;
                    top: 0;
                    unicode-bidi: isolate;
                    font-variant-numeric: tabular-nums;
                    text-transform: none;
                    text-indent: 0px !important;
                    text-align: start !important;
                    text-align-last: start !important;
                    font-size: 40px;
                    top: -12px;
                }
            }
        }
    }

    &-ShortButton {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #DE4C47;
        cursor: pointer;

        @include mobile {
            font-size: 12px;
            line-height: 20px;
        }        
    }

    &-ForYou {
        display: flex;
        column-gap: 40px;
        @include mobile {
            flex-direction: column;
            row-gap: 16px;
        }
        &-Container{
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            @include mobile {
                row-gap: 4px;                
            }
        }
        &-Title{
            font-family: 'PP Pangram Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #000000;
            @include mobile {
                font-size: 12px;
                line-height: 14px;
            }
        }
        &-Wrap{
            display: flex;
            column-gap: 8px;
        }
        &-Row {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
            @include mobile {
                row-gap: 4px;
            }
        }
        &-Text{
            background-color: #FFEAF0;
            padding: 8px 16px;
            font-family: 'PP Pangram Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.05em;
            color: #DE4C47;
            text-align: center;
            @include mobile {
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    &-Stock {
        font-weight: bold;
        order: 0;

        @include desktop {
            margin-inline-end: 24px;
        }

        @include mobile {
            font-style: normal;
            font-size: 12px;
            line-height: 22px;
            margin-block-start: 8px;
        }
    }

    &-Sku {
        order: 1;

        @include mobile {
            font-weight: normal;
            font-size: 12px;
            margin-block-start: 4px;
            text-align: end;
        }

        & + .ProductActions-Sku {
            margin-inline-start: 1ex;
        }
    }

    &-Review {
        order: 2;
        font-weight: 700;
        cursor: pointer;
        inset-block-end: -2px;
        font-size: 14px;

        @include mobile {
            inset-block-end: 0;
        }

        &Text {
            color: var(--primary-base-color);

            &:hover {
                color: var(--primary-dark-color);
            }

            &_isNotSafariOrIos {
                @include mobile {
                    vertical-align: -webkit-baseline-middle;
                }
            }
        }
    }

    &-Section {
        &_type {
            &_sku {
                display: flex;

                @include mobile {
                    flex: 1;
                    align-items: flex-end;
                    flex-direction: column;
                }

                .TextPlaceholder {
                    line-height: 30px;
                }
            }

            &_alerts {
                margin-block-start: 10px;
                margin-block-end: 10px;
            }
        }
    }

    &-Schema {
        min-width: 150px;
    }

    &-SchemaUrl {
        display: none;
    }

    &-PriceWrapper {
        .TextPlaceholder {
            line-height: 30px;
        }
    }

    &-ActionButtons {
        display: flex;
    }

    &-ActionsWrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-block-end: 12px;

        &:nth-child(2) {
            @include mobile {
                align-items: flex-start;
            }
        }

        &:nth-child(3) {
            @include mobile {
                margin-block-end: 22px;
            }
        }

        @include desktop {
            margin-block: 18px;
        }

        .ProductCompareButton {
            margin-inline-start: auto;
        }

        &_isWithoutPriceTotal .ProductActions-Section_type_sku {
            @include mobile {
                float: end;
            }
        }
    }
    
    .ProductConfigurableAttributes-Title {
        margin-top: 0;
    }

    .ProductConfigurableAttributes-SwatchList, .ProductConfigurableAttributes-DropDownList {
        margin: 0;
    }

    &-Block {
        display: flex;
        column-gap: 40px;
        &-Wrap {
            display: flex;
            flex-direction: column;
            row-gap: 8px;
        }
        &-Title {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #000000;
        }
        .Field-Wrapper_type_numberWithControls{
            background: #FFFFFF;
            border: 1px solid #D9D9D9;
            height: 45px;
        }
        .Field {
            margin-top: 0;
        }
    }

    &-Qty {
        &.Field {
            margin-block: 0;

            @include desktop {
                // margin-inline-end: 32px;
                width: 100%;
                //padding: 8px 10px;
                //column-gap: 13px;

                button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 4px;
                    width: 28px;
                    height: 40px;
                    background: #FFFFFF;
                    //border: 1px solid #D9D9D9;

                    &:hover {
                        background: black;
                        svg {
                            fill: white;
                            stroke: white;
                        }
                    }
                    svg {
                        fill: black;
                    }
                }

                input {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    width: 40px;
                    height: 40px;
                    background: #FFFFFF;
                    //border: 1px solid #D9D9D9;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #000000;
                }
            }

            @include mobile {
                // margin-inline-end: 12px;
                width: 100%;
                min-width: 130px;
                padding: 0px;
                justify-content: space-between;

                button {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 10px 4px;
                    width: 28%;
                    height: 46px;
                    background: #FFFFFF;
                    //border: 1px solid #D9D9D9;

                    &:hover {
                        background: black;
                        svg {
                            fill: white;
                            stroke: white;
                        }
                    }
                    svg {
                        fill: black;
                    }
                }

                input {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                    min-width: 36px;
                    width: 44%;
                    height: 46px;
                    background: #FFFFFF;
                    //border: 1px solid #D9D9D9;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 16px;
                    text-align: center;
                    letter-spacing: 0.05em;
                    color: #000000;
                }
            }
        }

        button {
            &:not([disabled]) {
                cursor: pointer;
            }

            &:disabled {
                cursor: default;
            }

            &:active {
                border-color: var(--primary-dark-color);
            }

            &:hover {
                border-color: var(--primary-dark-color);
            }
        }
    }
    
    &-Qty {
        border: 1px solid #8f8f8f;

        button {
            border: none !important;
            &:hover {
                background: black;
                svg {
                    fill: white;
                }
            }
        }

        input {
            width: 32px;
            min-width: 32px;
        }
    }

    &-AddToCartFixed {
        @include mobile-bottom-wrapper {
            padding: 12px 16px;
            inset-block-end: 16px;
            display: flex;
        }

        @include mobile {
            height: 48px;
            // inset-block-end: var(--footer-nav-height);
            transform: translateY(0)!important;
            inset-block-end: 0;
            padding: 0;
            border: none;
            .AddToCart {
                border-radius: 0;
                span {
                    font-family: 'Montserrat';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: 0.1em;
                    color: #FFFFFF;
                }
                svg {
                    fill: none;
                    path {
                        stroke: white;
                        stroke-width: 2px;                
                    }
                }
            }
        }

        .ProductWishlistButton {
            margin-inline: 18px 4px;
            margin-block: auto;

            @include mobile {
                display: none;
            }
        }

        .Field {
            &-Wrapper {
                &_type {
                    &_numberWithControls {
                        @include mobile {
                            display: flex;
                            width: 45%;
                        }
                    }
                }
            }
        }
    }

    &-Price {
        column-gap: 16px;
        align-items: flex-end;
        @include mobile {
            column-gap: 8px;
        }
    }
    .ProductPrice {
        &-HighPrice {
            order: 1;
            margin: 0!important;
            padding: 0!important;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            text-decoration-line: line-through;
            color: #818284;
            @include mobile {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &-Price, &-Price span {
            margin: 0!important;
            padding: 0!important;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: #000000;
            @include mobile {
                font-size: 18px;
                line-height: 22px;
            }
        }
        &-DiscountPercentage {
            order: 2;
            margin: 0!important;
            padding: 0!important;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            text-transform: uppercase;
            color: #1ABF66;
            background-color: #FFFFFF;
            @include mobile {
                font-size: 14px;
                line-height: 22px;
            }
        }
    }

    &-TryOn {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 302px;
        height: 40px;
        column-gap: 8px;
        background-color: #EED899;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1em;
        color: #000000;
        padding-inline: 30px;

        svg {
            stroke: #000000;
            width: 18px;
            height: 18px;
        }

        @include mobile {
            position: fixed;
            bottom: 60px;
            right: 10px;
            z-index: 1;
            box-shadow: 0px 4px 4px #00000040;
            border-radius: 70px;
            padding: 12px;
            width: fit-content;
            background-color: #EED899;
        }
    }

    &-AddToCartWrapper {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-block-start: 24px;
        column-gap: 24px;

        .ProductActions-AddToCart {
            //margin-inline-end: 16px;
            width: 45%;
            max-width: 302px;
            height: 40px;
            background-color: #000000;
            span {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 900;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.1em;
                color: #FFFFFF;
            }
            svg {
                fill: none;
                path {
                    stroke: white;
                    stroke-width: 2px;                
                }
            }
            &:hover {
                width: 45%;
                max-width: 302px;
                height: 40px;
                border: 1px solid black;
                background-color: #FFFFFF;
                span {
                    color: #000000;
                }
                svg {
                    fill: none;
                    path {
                        stroke: black;
                    }
                }
            }

            @include ultra-narrow-desktop {
                //order: 10;
                //margin-block-start: 24px;
            }
        }

        .ProductCompareButton,
        .ProductWishlistButton {
            margin-inline: 16px;
        }

        @include ultra-narrow-desktop {
            column-gap: 0;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        &_isPrerendered {
            @include mobile {
                inset-block-end: 0;
            }
        }

        .Field-ErrorMessages {
            position: absolute;
        }
    }

    &-SABanner{
        display: flex;
        padding: 8px 50px;
        background: #FAF6EB;
        width: 100%;
        span {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #161616;
            
        }
        a {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #71333F;
        }
    }

    &-AttributesWrapper {
        @include mobile {
            // margin-block-end: 16px;
        }

        &.animate {
            animation: shake 820ms .5ms cubic-bezier(.36, .07, .19, .97) both;
        }
    }

    &-Halal {
        display: flex;
        column-gap: 28px;
        width: fit-content;

        img {
            width: 115px;
            height: 54px;            
        }

        div {
            border: 1px solid #D9D9D9;
            height: 54px;

            &:last-child {
                display: none;
            }
        }

        @include mobile {
            column-gap: 10px;

            img {
                width: calc(90% / 3);
                height: fit-content;
            }

            div {
                height: auto;
            }
        }
    }

    &-GroupedItems {
        margin-block-start: 24px;
    }

    &-Reviews {
        //margin-block-end: 10px;
        display: flex;

        svg {
            width: 24px;
            height: 24px;
        }

        @include mobile {
            margin-block-end: 0;
            svg {
                width: 18px;
                height: 18px;
            }
        }

        .ProductReviewRating {
            margin-inline-end: 24px;
        }
    }

    .ProductConfigurableAttributes-Expandable {
        margin-block-start: 24px;
    }

    .ExpandableContent:first-of-type {
        border-block-start: 0;
    }
}
