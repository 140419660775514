/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable */
.ProductAlert {
  &-Heading {
    margin-bottom: 10px;
    color: #282c3f;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
  }
  &-Form {
    display: flex;
    align-items: center;
    div, input {
      width: 100%;
    }
    button {
      white-space: nowrap;
      width: 30%;
    }
  }
  &-Input {
    margin: 0;
  }
  &-ErrorMessage{
    color: #cd0000;
    margin-block: 10px;
  }
  &-SuccessMessage{
    color: #64BC26;
    margin-block: 10px;
  }
}
