/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../../../../src/style/abstract/variables';
@import '../../../../../src/style/abstract/media';
@import '../../../../../src/style/abstract/button';
@import '../../../../../src/style/abstract/loader';
@import '../../../../../src/style/abstract/icons';
@import '../../../../../src/style/abstract/parts';

.ProductRecommendation {
    margin-bottom: 30px;
    @include mobile {
        margin-top: 40px;
    }
    &-ProductList {
        display: grid;
        // grid-template-columns: repeat(2, 1fr) !important;
        gap: 30px;
        margin-top: 80px;
        @include mobile {
            grid-template-columns: repeat(3, 1fr) !important;
            gap: 15px;
            margin-top: 30px;
            @media (max-width: 680px) {
                grid-template-columns: repeat(2, 1fr) !important;
            }
            @media (max-width: 405px) {
                gap: 15px 5px;
            }
        }
    }
    &-Heading {
        color: var(--color-blue);
        font-size: 14px;
        margin-top: 20px;
        font-weight: bold;
    }
}
